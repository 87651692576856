const itemsWithImages = [
  {
    id: 1,
    name: "Item 1",
    images: [
      {
        id: 1,
        image: "https://jaz-properties.s3.amazonaws.com/vtsr/vtsr_mbl1.jpg",
        title: "Title",
        mobileImage: "https://jaz-properties.s3.amazonaws.com/vtsr/VTRS1.png",
        description: "Description for image 1 of Item 4",
      },
      {
        id: 2, 
        image: "https://jaz-properties.s3.amazonaws.com/vtsr/vtsr_mbl2.jpg",
        title: "Title",
        mobileImage: "https://jaz-properties.s3.amazonaws.com/vtsr/VTRS2.png",
        description: "Description for image 2 of Item 4",
      },
      {
        id: 3,
        image:"https://jaz-properties.s3.amazonaws.com/vtsr/vtsr_mbl3.jpg",
        title: "Title",
        mobileImage: "https://jaz-properties.s3.amazonaws.com/vtsr/VTRS3.png",
        description: "Description for image 3 of Item 4",
      },
       {
        id: 3,
        image:"https://jaz-properties.s3.amazonaws.com/vtsr/vtsr_mbl4.jpg",
        title: "Title",
        mobileImage:"https://jaz-properties.s3.amazonaws.com/vtsr/VTRS4.png",
        description: "Description for image 4 of Item 4",
      },
    ],
  },
  {
    id: 2,
    name: "Our office",
    images: [
      {
        id: 1,
        image: "https://jaz-properties.s3.amazonaws.com/ouroffice/jaz1.jpg",
        title: "Second title",
        mobileImage:  "https://jaz-properties.s3.amazonaws.com/ouroffice/jaz1.jpg",
        description: "Description for image 1 of Item 3",
      },
      {
        id: 2,
        image: "https://jaz-properties.s3.amazonaws.com/ouroffice/jaz2.jpg",
        title: "Second title",
        mobileImage: "https://jaz-properties.s3.amazonaws.com/ouroffice/jaz2.jpg",
        description: "Description for image 2 of Item 3",
      },
        {
        id: 2,
        image: "https://jaz-properties.s3.amazonaws.com/ouroffice/jaz3.jpg",
        title: "Second title",
        mobileImage: "https://jaz-properties.s3.amazonaws.com/ouroffice/jaz3.jpg",
        description: "Description for image 3 of Item 3",
      },
    ],
  },
  {
    id: 3,
    name: "Mahal",
    images: [
      {
        id: 1,
        image: "https://jaz-properties.s3.amazonaws.com/rosemahal/rosemahal_mbl.jpg",
        title: "Third title",
        mobileImage: "https://jaz-properties.s3.amazonaws.com/rosemahal/rosemahal.jpeg",
        description: "Description for image 1 of Item 1",
      },
      // {
      //   id: 2,
      //   image: ROSEMAHAL2,
      //   title: "Third title",
      //   mobileImage: RoseMahalMobile2,
      //   description: "Description for image 2 of Item 3",
      // },
    ],
  },
  {
    id: 4,
    name: "Textile",
    images: [
      {
        id: 1,
        image: "https://jaz-properties.s3.amazonaws.com/usb/USB2.jpg",
        title: "Fourth title",
        mobileImage: "https://jaz-properties.s3.amazonaws.com/usb/usb_mbl2.png",
        description: "Description for image 1 of Item 4",
      },
      {
        id: 2,
        image: "https://jaz-properties.s3.amazonaws.com/usb/USB3.jpg",
        title: "Fourth titles",
        mobileImage: "https://jaz-properties.s3.amazonaws.com/usb/usb_mbl3.png",
        description: "Description for image 2 of Item 4",
      },
      {
        id: 3,
        image: "https://jaz-properties.s3.amazonaws.com/usb/USB4.jpg",
        title: "Fourth titles",
        mobileImage: "https://jaz-properties.s3.amazonaws.com/usb/usb_mbl4.png",
        description: "Description for image 3 of Item 4",
      },
    ],
  },
  {
    id: 5,
    name: "Franchize",
    images: [
      {
        id: 1,
        image: "https://jaz-properties.s3.amazonaws.com/markas/markas_mbl1.jpg",
        title: "Titles",
        mobileImage: "https://jaz-properties.s3.amazonaws.com/markas/Markas1.jpg",
        description: "Description for image 1 of Item 1",
      },
            {
        id: 2,
        image: "https://jaz-properties.s3.amazonaws.com/markas/markas_mbl2.jpg",
        title: "Titles",
        mobileImage: "https://jaz-properties.s3.amazonaws.com/markas/Markas2.jpg",
        description: "Description for image 1 of Item 1",
      },
            {
        id: 3,
        image: "https://jaz-properties.s3.amazonaws.com/markas/markas_mbl3.jpg",
        title: "Titles",
        mobileImage: "https://jaz-properties.s3.amazonaws.com/markas/Markas3.jpg",
        description: "Description for image 1 of Item 1",
      },
            {
        id: 4,
        image: "https://jaz-properties.s3.amazonaws.com/markas/markas_mbl4.jpg",
        title: "Titles",
        mobileImage:  "https://jaz-properties.s3.amazonaws.com/markas/markas4.png",
        description: "Description for image 1 of Item 1",
      },
    ],
  },
  {
    id: 6,
    name: "Medical center",
    images: [
      {
        id: 1,
        image: "https://jaz-properties.s3.amazonaws.com/Medical center/TCM_mbl.png",
        title: "Title ",
        mobileImage: "https://jaz-properties.s3.amazonaws.com/Medical center/TMC.jpg",
        description: "Description for image 1 of Item 4",
      },
      {
        id: 2,
        image: "https://jaz-properties.s3.amazonaws.com/Medical center/Vallam1_mbl.png",
        title: "Title 6",
        mobileImage: "https://jaz-properties.s3.amazonaws.com/Medical center/VALLAM  (1).jpeg",
        description: "Description for image 2 of Item 4",
      },
      {
        id: 2,
        image: "https://jaz-properties.s3.amazonaws.com/Medical center/Vallam2_mbl.png",
        title: "Title 6",
        mobileImage: "https://jaz-properties.s3.amazonaws.com/Medical center/VALLAM  (2).jpeg",
        description: "Description for image 2 of Item 4",
      },
    ],
  },
];
export default itemsWithImages;
