import VTSR from "../../../../Assets/new_images/VTSRnew.jpg"
import Ram from "../../../../Assets/new_images/RamaNew.jpg"
import CroppedIMage from "../../../../Assets/new_images/MicrosoftTeams-image (88).jpg"
import TMC from "../../../../Assets/new_images/rosemahal_mbls.jpg"
import VTSRNEW from "../../../../Assets/new_images/MicrosoftTeams-image (8s8).jpg"
import SivaKumar from "../../../../Assets/new_images/3d-rendering-house-model (1).jpg"
const homegalleryMainImage = [
    {
      id: 1,
      name: "Item 1",
      images: [
        {
          id: 1,
          image: VTSRNEW,
          title: "Title",
          mobileImage: VTSRNEW,
          description: "Description for image 1 of Item 4",
          project:"vtsr"
        },
        {
            id: 2,
            image: "https://jaz-properties.s3.amazonaws.com/ouroffice/jaz1.jpg",
            title: "Second title",
            mobileImage:  "https://jaz-properties.s3.amazonaws.com/ouroffice/jaz1.jpg",
            description: "Description for image 1 of Item 3",
            project:"OurOffice"
          },  {
            id:3,
            image: SivaKumar,
            title: "Third title",
            mobileImage: SivaKumar,
            description: "Description for image 1 of Item 1",
            project:"Mahal"
          },
      ],
    },
    {
      id: 2,
      name: "item1",
      images: [
        {
            id: 1,
            image: CroppedIMage,
            title: "Fourth title",
            mobileImage: "https://jaz-properties.s3.amazonaws.com/usb/usb_mbl2.png",
            description: "Description for image 1 of Item 4",
            project:"Textile"
          },
          {
            id: 2,
            image: Ram,
            title: "Titles",
            mobileImage: "https://jaz-properties.s3.amazonaws.com/markas/Markas2.jpg",
            description: "Description for image 1 of Item 1",
            project:"Franchize"
          },
          {
            id: 3,
            image: TMC,
            title: "Title ",
            mobileImage: "https://jaz-properties.s3.amazonaws.com/Medical center/TMC.jpg",
            description: "Description for image 1 of Item 4",
            project:"MedicalCenter"
          },
      ],
    },
    
  ];
  export default homegalleryMainImage;
  