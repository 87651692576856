import React, { useEffect, useRef, useState } from "react";
import Card from "../../components/Card/Card";
import { useNavigate } from "react-router-dom";

const Service = () => {
  const customStyles = {
    borderTopRightRadius: "0%",
    borderBottomRightRadius: "40% 80%",
    borderBottomLeftRadius: "0",
    borderTopLeftRadius: "40% 80%",
  };
  const servicesSectionRef = useRef(null);
  const headingSectionRef = useRef(null);
  const navigate = useNavigate();
  const imgRef = useRef<HTMLImageElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div id="service" className="md:p-10 overflow-hidden lg:h-[90vh]">
      <div>
        <div>
          <h2 className="flex justify-center p-1 text-3xl font-semibold">
            Our Services
          </h2>
          <p className="text-lg text-center">
            We provide several services that benefit customers
          </p>
        </div>
      </div>

      <ul className={`flex  flex-col sm:flex-row gap-5 justify-around p-12 overflow-hidden `}>
        {Card.map(({ image, name, description }) => (
          <li
            key={name}
            className="flex flex-col gap-5 items-center p-13 border-gray-400 shadow-lg group cursor-pointer"
          >
            <img
              className="border border-solid p-1 group-hover:scale-110 duration-200 "
              style={customStyles}
              src={image}
              alt="service "
              onClick={() => {
                navigate(`/service/${name.split(" ").join("")}`);
                console.log(name);
              }}
            />
            <h2 className="text-xl font-medium">{name}</h2>
            <p className="p-4">{description}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Service;
