import construction from "../../Assets/construction.png";
import realestate from "../../Assets/real estate.png";
import interior from "../../Assets/interior.png";
const Card = [
  {
    image: construction,
    name: "Construction",
    description:
      "With a passion for craftsmanship and a commitment to delivering exceptional results, we bring your construction projects to life",
  },
  {
    image: realestate,
    name: "Real Estate",
    description:
      " As a trusted name in the industry, we specialize in connecting discerning buyers and sellers with their perfect properties.",
  },
  {
    image: interior,
    name: "Interior Design",
    description:
      "We are a leading interior design firm dedicated to creating stunning environments that reflect your unique style and aspirations.",
  },
];

export default Card;
